<template>
    <CContainer id="advancedReport">
        <CRow class="title">
            <template>
                <h1>店周情報通：深度數據 x 客流活動時間 x 區域人潮時間段分佈</h1>
                <p>用更深度的數據，剖析解您想評估的地點，精準掌握地點人潮動向與趨勢。​</p>
            </template>
        </CRow>
        <CRow>
            <CCol md="8">
                <div class="apply">
                    <CIcon name="cilPencil" />
                    需求申請
                </div>
                <ValidationObserver v-slot="{ invalid }" ref="observer">
                <CRow>
                    <CCol sm="6">
                        <ValidationProvider name="姓名" rules="required" v-slot="{ errors }">
                            <CInput v-model="formData.name">
                                <template #label>
                                    申請人姓名*
                                </template>
                            </CInput>
                            <span class="error-message">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </CCol>
                    <CCol sm="6">
                        <ValidationProvider name="電子信箱​" rules="required|email" v-slot="{ errors }">
                            <CInput v-model="formData.email" >
                                <template #label>
                                    Email(報告將寄送至該電子信箱)*
                                </template>
                            </CInput>
                            <span class="error-message">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </CCol>
                </CRow>
                <multiselect 
                    v-model="reportCount" 
                    :options="reportCountOptions"
                    track-by="count"
                    :multiple="false" 
                    label="label"
                    selectLabel="" 
                    selectedLabel="" 
                    deselectLabel=""
                    :allow-empty="false" 
                    :preselectFirst="true" 
                    :searchable="false" 
                    :close-on-select="true" 
                    @input="updateCount(reportCount)">
                </multiselect>

                <div class="totalPrice">{{ `費用總計 新台幣 ${ totalPrice } 元` }}</div>
                <CRow style="max-width: 100%; margin-left: 0px;">
                    <CTabs variant="pills" id="advancedReportTabs">
                        <template v-for="(tabTitle, index) in tabTitles">
                            <CTab 
                                :active="tabTitle.id === 1" 
                                :title="tabTitle.title" 
                                :key="tabTitle.id"
                            >
                                <SiteCard 
                                    v-if="dataSuccessLoaded"
                                    :index="index + 1"
                                    :parentTypeList="typeList"  
                                    :parentCompetitorList="CompetitorList" 
                                    :parentAdl2List = Adl2List
                                    :parentAdl5List = Adl5List
                                    @siteValidate="siteValidate"
                                />
                                <span v-if="!allSiteValid" class="invalid-message">
                                    <template v-for="tab in tabTitles">
                                        <a href="#" @click="clickTab(tab.id)">
                                            {{ `${!tab.valid ? tab.title : ``}` }}
                                        </a>
                                    </template>
                                    有欄位尚未填寫或選取，請確認。
                                </span>
                            </CTab>
                        </template>

                    </CTabs>
                </CRow>
                <p style="text-align: end; margin: -12px 0 10px 0; color: #fff;">
                    <span>作業時程：當您收到受理通知信後10個工作天完成。</span>
                </p>
                <CButton
                    type="submit"
                    :color="'success'"
                    :size="'sm'"
                    @click="getAdvancedReport"
                    :disabled="!allSiteValid || invalid"
                >
                    確認表單
                </CButton>
            </ValidationObserver>
            </CCol>
            <CCol md="4">
                <a class="templateReport" href="https://bit.ly/advancereporttemplate" target="_blank">
                    <div>
                        <p>閱讀</p>
                        <p>範例報告</p>
                        <span>sample report</span>
                    </div>
                    <img src="/img/sidebar/advancedReport/arrow.svg" width="45" height="45" />
                </a>
            </CCol>
            <!-- <img class="bg-img" src="/img/sidebar/advancedReport/green circle @2x.png" width="500" height="500"> -->
        </CRow>

        <CheckReportModal
            :showModal="showModal"
            :tabTitles="tabTitles"
            :parentCompetitorList="CompetitorList" 
            @closeModal="closeModal"
        />
    </CContainer>
</template>

<script>
import axios from 'axios';
import appSettings from '../../util/settings';
import { mapState } from 'vuex';
import store from '../../util/store.js';
import SiteCard from '../../components/AdvancedReport/SiteCard';
import CheckReportModal from '../../components/AdvancedReport/CheckReportModal';
import Multiselect from 'vue-multiselect';

export default {
    data() {
        return {
            state: store.state,
            dataSuccessLoaded: false,
            formData: {
                name: "",
                email: ""
            },
            reportCountOptions: [
                {
                    count: 1,
                    label: `1份`
                },
                {
                    count: 2,
                    label: `2份`
                },
                {
                    count: 3,
                    label: `3份`
                }
            ],
            Adl2List: [],
            Adl5List: [],
            typeList: [],
            CompetitorList: [],
            // ignoreCompetitorTypeList: ['3C', 'Clinic', 'Home Goods', 'Apparel'],
            reportCount: {
                count: 1,
                label: `1份`
            },
            price: 5199,
            tabTitles: [{
                id: 1, 
                title: `報告1`,
                valid: false
            }],
            showModal: false,
            allSiteValid: false,
        }
    },
    components: {
        SiteCard,
        CheckReportModal,
        Multiselect,
    },
    computed: {
        ...mapState({
            advancedReportFormData: state => state.advancedReportFormData,
        }),
        totalPrice() {
            return (this.reportCount.count * this.price).toLocaleString();
        }
    },
    watch: {
        formData: {
            handler: function(val) {
                // console.log(`val`, val);
                // console.log(`advancedReportFormData`, this.advancedReportFormData);
                this.advancedReportFormData.name = val.name;
                this.advancedReportFormData.email = val.email;
                store.commit('set', ['advancedReportFormData', this.advancedReportFormData]);
            },
            deep: true
        },
    },
    created() {
        this.getAdl2List();
        this.getAdl5List();
        this.getCompetitorList();
    },
    mounted() {
        this.addLabelCountSelect();
    },
    methods: {
        getAdl2List() {
            axios.post(appSettings.GetAdl2List,
                {
                    // userToken: store.state.token,
                })
                .then(this.getAdl2ListResult)
                .catch(function (error) {
                    console.log(error);
                });
        },
        getAdl2ListResult(res) {
            // console.log(`getAdl2ListResult`, res);
            this.Adl2List = res.data;
        },
        getAdl5List() {
            axios.post(appSettings.GetAdl5List,
                {
                    // userToken: store.state.token,
                })
                .then(this.getAdl5ListResult)
                .catch(function (error) {
                    console.log(error);
                });
        },
        getAdl5ListResult(res) {
            // console.log(`getAdl5ListResult`, res);
            this.Adl5List = res.data;
        },
        getCompetitorList() {
            axios.post(appSettings.GetCompetitorList,
                {
                    // userToken: store.state.token,
                })
                .then(this.competitorListResult)
                .catch(function (error) {
                    console.log(error);
                });
        },
        async competitorListResult(res) {
            // if(res) this.dataSuccessLoaded = true;
            // this.handleData(res.data);
            this.dataSuccessLoaded = await this.handleData(res.data);
        },
        handleData(data) {
            // console.log(`data`, data);
            this.typeList = [];

            data.forEach(x => {
                this.typeList.push({
                    GType_l: x.GType_l,
                    GType: x.GType
                });
            })

            this.CompetitorList = data;

            // console.log(`typeList`, this.typeList);
            // console.log(`this.CompetitorList`, this.CompetitorList);

            return true;
        },
        async updateCount(value) {
            // console.log(`value`, value);
            
            this.reportCount = value;    
            let _count = this.reportCount.count;

            for(let i = 1; i <= _count; i++) {
                if(this.tabTitles.length >= i) {
                    continue;
                }
                switch(i) {
                    case 1:
                        this.tabTitles.push({id: 1, title: `報告1`, valid: false});
                        break;
                    case 2:
                        this.tabTitles.push({id: 2, title: `報告2`, valid: false});
                        break;
                    case 3:
                        this.tabTitles.push({id: 3, title: `報告3`, valid: false});
                        break;
                }
            };

            if(_count < this.tabTitles.length) {
                const diff = this.tabTitles.length - _count;

                await this.removeTab(diff)
                this.clickTab(this.tabTitles.length);
            }

            this.checkSiteValid();
        },
        removeTab(diff) {
            for(let i = 0; i < diff; i++) {
                this.tabTitles.pop();
            };
        },
        clickTab(index) {
            this.$nextTick(() => {
                const tab = document.querySelectorAll(`#advancedReportTabs > div > ul > li > .nav-link`);
                // console.log(`tab`, tab);
                tab[index - 1].click();
            });
        },
        getAdvancedReport() {
            this.showModal = true;
            // console.log(`getAdvancedReport`, store.state.advancedReportFormData);
        },
        closeModal() {
            this.showModal = false;
        },
        siteValidate(obj) {
            this.tabTitles.forEach(x => {
                if(x.id === obj.index) {
                    x['valid'] = obj.valid;                    
                }
            });

            // console.log(`tabTitles`, this.tabTitles);
            this.checkSiteValid();
            
        },
        checkSiteValid() {
            // console.log(`isValid`, this.tabTitles.every(x => x.valid));
            this.allSiteValid = this.tabTitles.every(x => x.valid);
        },
        addLabelCountSelect() {
            const tags = document.querySelector("#advancedReport .multiselect .multiselect__tags");
            // console.log(`tags`, tags);
            
            let label = document.createElement('label');
            label.append("所需購買報告份數");
            tags.prepend(label);
        },
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style> 
<style scoped>
.container {
    padding: 10px 15px;
}
.title {
    color: #FAFAFA;
}

.title h1 {
    font-size: 24px;
    font-weight: 700;
}

.title p {
    font-size: 16px;
    font-weight: 400;
}

.baseData {
    padding-left : 0;
}

.apply {
    color: #FAFAFA;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.form-group {
    background-color: #222222;
    color: #FAFAFA;
    border-radius: 8px;
    padding: 0.175rem 0.75rem;
    margin-bottom: 0;
}

::v-deep #advancedReportTabs > div > .tab-content {
    position: relative;
}

.invalid-message {
    position: absolute;
    color: #FF9292;
    bottom: 0.4rem;
    left: 1.25rem;
}

.invalid-message a {
    color: #FF9292;
    cursor: pointer;
}

::v-deep #advancedReportTabs > div > ul > li > .nav-link {
    background-color: #343434;
    color: rgba(227, 227, 227, 0.7);
    border: 1px #343434 solid;
}

::v-deep #advancedReportTabs > div > ul > li > .nav-link:hover {
    color: rgba(146, 200, 176, 1);
}

::v-deep #advancedReportTabs > div > ul > li > .nav-link.active {
    border-bottom-color: rgba(146, 200, 176, 1);
    color: rgba(146, 200, 176, 1);
    border-style: solid;
}

::v-deep #advancedReportTabs > div > .nav.nav-pills > li:first-child > .nav-link {
    border-top-left-radius: 5px;
}

::v-deep #advancedReportTabs > div > .nav.nav-pills > li:last-child > .nav-link{
    border-top-right-radius: 5px;
}

::v-deep .nav-pills .nav-link {
    border-radius: 0;
}

.invalid {
    background-color: red;
}

::v-deep input.form-control,
::v-deep select.form-control {
    background-color: transparent;
    color: #FAFAFA;
    border: none;
    padding: 0;
    font-size: 14px !important;
}

::v-deep select.form-control {
    cursor: pointer;
}

::v-deep select.form-control option {
    color: #F6F6F6 !important;
    background-color: #2A2A29;
    cursor: pointer;
}

::v-deep .multiselect__select {
    bottom: 0;
    top: 30px;
}

::v-deep .multiselect .multiselect__tags {
    padding: 11px 43px 0 2px;
}

::v-deep .multiselect__tags {
    cursor: pointer;
    background-color: #222222;
    color: #F6F6F6;
    border: 0;
}

::v-deep .multiselect__tags:has(> label) {
    display: flex;
    flex-direction: column;
}

::v-deep .multiselect__tags > label {
    padding-left: 10px;
}

::v-deep .multiselect__single {
    background: #222222;
    padding-left: 10px;
}

::v-deep .multiselect .multiselect__content-wrapper {
    padding-bottom: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
}

::v-deep .multiselect__content-wrapper {
    border: 0;
}

::v-deep .multiselect .multiselect__option {
    background-color: #2A2A29;
    color: #F6F6F6;
    padding-left: 0.8rem;
}

::v-deep .multiselect .multiselect__option:hover {
    background-color: #6A6A6C;
}

::v-deep .multiselect .multiselect__option--selected, 
::v-deep .multiselect .multiselect__option.multiselect__option--highlight.multiselect__option--selected, 
::v-deep .multiselect .multiselect__option.multiselect__option--group.multiselect__option--group-selected, 
::v-deep .multiselect .multiselect__option--group-selected.multiselect__option--highlight {
    background-image: none;
}

::v-deep .form-control:focus {
    box-shadow: none;
}

::v-deep select.form-control option{
    color: initial;
}

.error-message {
    display: inline-block;
    color: #FF9292;
    margin-left: 10px;
}

.totalPrice {
    color: #F6C857;
    font-size: 12px;
    font-weight: 700;
    text-align: end;
}

#advancedReportTabs > div > div > ::v-deep .tab-content {
    background-color: #222222
}

.btn {
    width: 100%;
    background: linear-gradient(90deg, #526636 0%, #284932 100%);
    color: rgba(250, 250, 250, 1);
    border: none;
    padding: 6px 0;
    font-size: 14px;
}

.btn:disabled {
    background: #4D4D4D;
    color: rgba(113, 113, 113, 1);
}

.btn:not(:disabled):hover {
    background: linear-gradient(90deg, #284932 0%, #526636 100%);
    color: rgba(250, 250, 250, 1);
}

.templateReport {
    display: inline-block;
    width: 100%;
    height: 50%;
    min-height: 300px;
    background-image: url("/img/sidebar/advancedReport/template report_background @1x.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    text-decoration: none;
    color: #E3F1EC;
}

.templateReport:hover {
    color: #F6C857;
}

.templateReport:hover img {
    filter: brightness(0) saturate(100%) invert(95%) sepia(39%) saturate(3145%) hue-rotate(316deg) brightness(102%) contrast(93%);
}

.templateReport div {
    margin: 12% 0 0 5%;
}

.templateReport p {
    margin-bottom: 0;
    font-size: 40px;
    line-height: 46px;
    font-weight: bold;
}

.templateReport span {
    font-size: 20px;
    font-weight: bold;
}

.templateReport img {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.bg-img {
    position: absolute;
    top: 250px;
    right: 0;
    z-index: 0;
}

@media (max-width: 900px) {
    .col-md-4 {
        margin-top: 20px;
    }
}

</style>