<template>
    <CModal :show.sync="isShow">
        <div v-if="isDoubleCheck">
            <h3>提醒</h3>
            <p>點擊「確定」按鈕及代表您已理解並同意以下內容：​</p>
            <ul>
                <li>
                    您已仔細審閱並確認所填寫的全部資料均正確無誤且符合您的意願和需求，您同意此為最終確認版本，不再進行任何修改、調整、變動。​
                </li>
                <li>
                    我司將於寄出受理通知後十個工作日內，提供店周情報通報告予貴方。受理通知發出前，不受此期限約束。​
                </li>
                <li>
                    您了解並同意，一旦付款成功，本公司將不予退款。所有退款事宜，本公司保留最終解釋權。​
                </li>
            </ul>
            <div class="mt-3" style="display: flex; justify-content: end;">
                <CButton
                    class="cancel"
                    variant="ghost"
                    :size="'sm'"
                    @click="isDoubleCheck=false"
                >
                    返回核對資料
                </CButton>
                <CButton
                    class="pay"
                    variant="ghost"
                    :size="'sm'"
                    @click="goToPay"
                >
                    確定
                </CButton>
            </div>
        </div>
        <div v-else>
            <h3>核對報告需求資料</h3>
            <CTabs variant="pills">
                <template v-for="tabTitle in tabTitles">
                    <CTab :ref="tabTitle.id" :active="tabTitle.id === 1" :title="tabTitle.title" :key="tabTitle.id">
                        <h4>地址</h4>
                        <span>{{ advancedReportFormData.reports[tabTitle.id - 1] ? advancedReportFormData.reports[tabTitle.id - 1]['formData']['address'] : `` }}</span>
                        <h4 class="mt-3">產業</h4>
                        <span>{{ advancedReportFormData.reports[tabTitle.id - 1] ? advancedReportFormData.reports[tabTitle.id - 1]['formData']['selectedType'] : `` }}</span>
                        <h4 class="mt-3">評估半徑</h4>
                        <span>{{ advancedReportFormData.reports[tabTitle.id - 1] ? advancedReportFormData.reports[tabTitle.id - 1]['formData']['serviceRadius'] : `` }}</span>
                        <h4 class="mt-3">追蹤品牌(上限5種品牌)</h4>
                        <template v-for="(brand, index) in advancedReportFormData.reports[tabTitle.id - 1] ? advancedReportFormData.reports[tabTitle.id - 1]['formData']['selectedBrands'] : ``">
                            <span>{{ getBrandL(brand) }}
                            {{ index !== advancedReportFormData.reports[tabTitle.id - 1]['formData']['selectedBrands'].length - 1 ? `、` : ``}}</span>
                        </template>
                    </CTab>
                </template>
            </CTabs>
            <a href="#" @click="editData">修改資料</a>
            <div class="mt-3" style="display: flex; justify-content: end;">
                <CButton
                    class="cancel"
                    variant="ghost"
                    :size="'sm'"
                    @click="isShow=false"
                >
                    取消
                </CButton>
                <CButton
                    class="pay"
                    variant="ghost"
                    :size="'sm'"
                    @click="isDoubleCheck=true"
                >
                    前往結帳
                </CButton>
            </div>
        </div>
    </CModal>
</template>

<script>
import { mapState } from 'vuex';
import store from '../../util/store.js';
import axios from 'axios';
import appSettings from '../../util/settings';
import CryptoJS from '../../util/CryptoJS';

export default {
    data() {
        return {
            state: store.state,
            isShow: false,
            Products: [],
            isDoubleCheck: false,
        }
    },
    props: ['showModal', 'tabTitles','parentCompetitorList'],
    computed: {
        ...mapState({
            advancedReportFormData: state => state.advancedReportFormData,
        })
    },
    watch: {
        showModal() {
            this.isShow = this.showModal
        },
        isShow(val) {
            if(!val) {
                this.$emit('closeModal', false)
            }
        }
    },
    created() {
        this.handleData();
        this.getProducts();
    },
    mounted() {
    },
    methods: {
        handleData() {
            this.advancedReportFormData['reports'] = this.advancedReportFormData.reports.sort((a, b) => a.index - b.index);
            // console.log(`this.advancedReportFormData`, this.advancedReportFormData);
        },
        editData() {
            this.isShow = false;
            let activeModalTabIndex = 0;
            const modalTabLinks = document.querySelectorAll(".modal-body .nav-pills .nav-item .nav-link");
            // console.log(`modalTabLinks`, modalTabLinks);
            
            modalTabLinks.forEach((x, index) => {
                if(x.classList.contains("active")) {
                    activeModalTabIndex = index;
                }
            })
            const siteReportTabs = document.querySelectorAll("#advancedReportTabs > div > .nav-pills > .nav-item");
            // console.log(`siteReportTabs`, siteReportTabs);
            siteReportTabs.forEach((x, index) => {
                if(index === activeModalTabIndex) {
                    x.click();
                }
            })
        },
        getProducts: function() {  
            var self = this;
            axios.post(appSettings.GetProducts, {
                channelId: appSettings.AdvancedReportChannelID
            })
              .then(function(response){ 
                  var data = response.data;  
                  if(data !== undefined && data.length > 0){
                    data.forEach(p => {
                      self.Products.push({ 
                          name: p.ProductName,
                          localName: p.ProductLocalizedName,
                          productPriceId: p.ProductPriceID,
                          Price: p.Price,
                          Currency: p.Currency,
                          ReportCount: p.ReportCount,
                      })
                    });
                  }
              //    console.log(`Products`, self.Products);
              })
              .catch(function (error) {  
                console.log(error);
              });          
          },
          goToPay(){
            var prodsArray = []; 
            let ProductInfo = this.Products[0];
            let _advancedReportFormData = this.advancedReportFormData;
            // let _advancedReportFormData = "test";
            const qty = this.tabTitles.length;

            for (var i = 0; i < qty; i++) { 
                prodsArray = prodsArray.concat(ProductInfo.productPriceId);
            };

            //儲存Advanced report data
            _advancedReportFormData['reports'] = _advancedReportFormData['reports'].slice(0, qty);
            _advancedReportFormData = JSON.stringify(_advancedReportFormData);
            // console.log(`_advancedReportFormData`, typeof(_advancedReportFormData), _advancedReportFormData);
            
            var token = store.state.token;
            var key = token.substr(0, 16);
            var iv = token.substr(16, 16);
            
            //傳多個產品價格ID,重覆代表買多個(用,分隔)加密 
            var encryptProds = CryptoJS.encrypt(prodsArray.join(","),key,iv); 
            var encryptAdvancedReport = CryptoJS.encrypt(_advancedReportFormData,key,iv); 

            let url = appSettings.AddToCar + "?Prods=" + encodeURIComponent(encryptProds) + "&userToken=" + encodeURIComponent(store.state.token) + "&advancedReport=" + encodeURIComponent(encryptAdvancedReport);
            
            store.commit("clearAdvancedReportFormData");
            //window.location = encodeURI(url); 
            window.location = url;
          },
          getBrandL(brandE){ 
            const brandL =  this.parentCompetitorList
                                .map(category => Object.values(category).flat())
                                .flat()
                                .filter(item => item.BrandE === brandE)
                                .map(item => item.BrandL)[0] || brandE;
            return brandL; 
          },
    },
}
</script>

<style scoped>
::v-deep .modal .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

::v-deep .modal-content {
    min-width: 400px;
    max-width: 400px;
}

::v-deep .modal-header {
    display: none;
}

::v-deep .modal-footer {
    display: none;
}

h3 {
    font-size: 20px;
    font-weight: 900;
}

::v-deep .nav {
    gap: 8px;
    margin-bottom: 10px;
}

::v-deep .nav-link {
    background-color: transparent;
    border: rgba(61, 117, 85, 0.70);
    color: rgba(61, 117, 85, 0.7);
}

::v-deep .nav-link:hover {
    border: rgba(61, 117, 85, 1);
    color: rgba(61, 117, 85, 1);
}

::v-deep .nav-link.active {
    border: rgba(61, 117, 85, 1);
    color: rgba(61, 117, 85, 1);
    background: rgba(61, 117, 85, 0.10);
}

h4 {
    font-weight: 900;
    margin: 3px 0;
    font-size: 16px;
}

span {
    display: inline-block;
    margin-bottom: 5px;
    font-weight: 600;
}

a {
    display: inline-block;
    color: rgba(61, 117, 85, 1);
    text-decoration: underline;
    margin-top: 10px;
}

a:hover {
    color: rgba(53, 101, 73, 1);
}

.btn {
    font-size: 0.875rem;
    padding: 0.375rem 0.75rem;
}

.btn:focus {
    box-shadow: none;
}

.cancel:hover {
    background-color: rgba(53, 101, 73, 1);
    color: #fff;
}

.pay {
    background-color: rgba(61, 117, 85, 1);
    color: rgba(250, 250, 250, 1);
    margin-left: 5px;

}

.pay:hover {
    background-color: rgba(53, 101, 73, 1);
}

ul {
    padding-left: 20px;
}

ul li {
    margin-bottom: 5px;
}

</style>