<template>
    <div>
        <!-- <img class="star" src="/img/sidebar/advancedReport/something like star @1x.png" width="40" height="40" alt=""> -->
        <CCard>
            <ValidationObserver ref="vo">
            <CCardBody>
                <CRow>
                    <CCol sm="3">
                        <ValidationProvider name="縣市" rules="required" v-slot="{ errors }">
                            <multiselect 
                                v-model="formData.selectedAdl2" 
                                :options="Adl2List" 
                                :multiple="false" 
                                selectLabel="" 
                                selectedLabel="" 
                                deselectLabel=""
                                :allow-empty="false" 
                                :searchable="false" 
                                :close-on-select="true"  
                                :placeholder="'所需評估縣市*'"
                                @input="updateAdl2(formData.selectedAdl2)">
                            </multiselect>
                            <span class="error-message">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </CCol>
                    <CCol sm="3">
                        <ValidationProvider name="區域" rules="required" v-slot="{ errors }">
                            <multiselect 
                                v-model="formData.selectedAdl5" 
                                :options="filteredAdl5List" 
                                :multiple="false" 
                                selectLabel="" 
                                selectedLabel="" 
                                deselectLabel=""
                                :allow-empty="false" 
                                :searchable="false" 
                                :close-on-select="true"  
                                :placeholder="'行政區*'"
                                @input="validateSlient">
                            </multiselect>
                            <span class="error-message">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </CCol>
                    <CCol sm="6">
                        <ValidationProvider name="地址" rules="required" v-slot="{ errors }">
                            <CInput 
                                v-model="formData.address"
                                :placeholder="'地址(不需填寫樓層)*'" 
                                @update:value="validateSlient"
                            />
                            <span class="error-message">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm="6">
                        <ValidationProvider name="產業*" rules="required" v-slot="{ errors }">
                            <multiselect 
                                v-model="formData.selectedType" 
                                :options="typeList_l" 
                                :multiple="false" 
                                selectLabel="" 
                                selectedLabel="" 
                                deselectLabel=""
                                :allow-empty="false" 
                                :searchable="false" 
                                :close-on-select="true"  
                                :placeholder="'請選擇有興趣的產業*'"
                                @input="validateSlient">
                            </multiselect>
                            <span class="error-message">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </CCol>
                    <CCol sm="6">
                        <ValidationProvider name="評估半徑*" rules="required" v-slot="{ errors }">
                            <multiselect 
                                v-model="formData.serviceRadius" 
                                :options="serviceRadiusList" 
                                :multiple="false" 
                                selectLabel="" 
                                selectedLabel="" 
                                deselectLabel=""
                                :allow-empty="false" 
                                :searchable="false" 
                                :close-on-select="true"  
                                :placeholder="'請選擇評估半徑*'"
                                @input="validateSlient">
                            </multiselect>
                            <span class="error-message">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </CCol>
                </CRow>
                <p class="desc">
                    請勾選您想了解的品牌*(可任選5種品牌)
                     <ValidationProvider name="noMessage*" rules="anyBrand">
                        <input type="text" style="display: none;" v-model.lazy="selectedBrands.length" />
                        <span v-if="!anyBrand" class="error-message">{{ `至少選擇一種品牌` }}</span>
                     </ValidationProvider>
                </p>
                <CTabs variant="pills">
                    <template v-for="(type, index) in typeList">
                        <CTab 
                            :active="index === 0" 
                            :title="type.GType_l" 
                            :key="type.GType" 
                            :id="'_'+replaceString(type.GType)"
                        >
                            <template v-for="brands in competitorList">
                                <template v-for="brand in brands[type.GType]">
                                    <input 
                                        :id="brand.BrandL"
                                        v-model="selectedBrands" 
                                        type="checkbox" 
                                        :value="brand.BrandL" 
                                        :disabled="brandsDisabled[brand.BrandL]" 
                                        @change="onChangeSelectedBrandCount"
                                    >
                                    <label :for="brand.BrandL">{{ brand.BrandL }}</label>
                                </template>
                            </template>
                        </CTab>
                    </template>
                </CTabs>
            </CCardBody>
            </ValidationObserver>
        </CCard>
    </div>
</template>

<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import store from '../../util/store.js';
import Multiselect from 'vue-multiselect';

export default {
    data() {
        return {
            Adl2List: [...this.parentAdl2List],
            Adl5List: [...this.parentAdl5List],
            ignoreAdl2List: ['金門縣', '連江縣', '澎湖縣'],
            filteredAdl5List: ['請先選擇縣市'],
            serviceRadiusList: ['250m', '500m', '1km', '2km'],
            typeList: [...this.parentTypeList],
            typeList_l: [],
            competitorList: [...this.parentCompetitorList],
            selectedBrands: [],
            brandsDisabled: {},
            maxBrandCount: 5,
            formData: {
                selectedAdl2: "",
                selectedAdl5: "",
                address: "",
                selectedType: "",
                serviceRadius: "",
                selectedBrands: [],
            },
            anyBrand: true,
            vo: undefined,
        }
    },
    components: {
        Multiselect
    },
    props: ['index', 'parentAdl2List', 'parentAdl5List', 'parentTypeList', 'parentCompetitorList'],
    computed: {
        advancedReportFormData() {
            return store.state.advancedReportFormData;
        }
    },
    watch: {
        formData: {
            handler: function(value) {
                // console.log(`value`, value);
                // console.log(`advancedReportFormData`, this.advancedReportFormData);

                const reports = {
                    index: this.index,
                    formData: value
                }
                const reportHasExisted = Object.values(this.advancedReportFormData.reports).find(x => x.index === this.index);
                if(!reportHasExisted) {
                    this.advancedReportFormData.reports.push(reports);
                }
                else {
                    Object.values(this.advancedReportFormData.reports).forEach(x => {
                        if(x.index === this.index) {
                            x.index = this.index;
                            x.formData = value;
                        }
                    });
                }
                store.commit('set', ['advancedReportFormData', this.advancedReportFormData]);
            },
            deep: true,
        },
        selectedBrands(newVal, val) {
            // console.log(`newVal`, newVal);
            if(newVal.length === 0) {
                this.anyBrand = false;
            }
            else {
                this.anyBrand = true;
            }

            this.validateSlient();
            
            let changeBrand;
            let action;
            // Find difference
            if(newVal.length > val.length) {
                changeBrand = newVal.filter(x => !val.includes(x))[0];
                action = 'add';
            }
            else {
                changeBrand = val.filter(x => !newVal.includes(x))[0];
                action = 'minus'
            }
            const type = this.findBrandType(changeBrand);
            this.handleBadge(action, type);
        },
    },
    created() {
        this.handleAdl2List();
        this.handleAdl5List();
        this.handleTypeList();
        this.handleCompetitorList();
    },
    mounted() {
        // console.log(`index`, this.index, typeof(this.index));
        // console.log(`Adl2List`, this.Adl2List);
    },
    methods: {
        handleAdl2List() {
            const filteredAdl2List = this.Adl2List.filter(x => !this.ignoreAdl2List.includes(x));
            this.Adl2List = filteredAdl2List;
            // console.log(`Adl2List`, this.Adl2List);
        },
        handleAdl5List() {
            this.Adl5List.forEach(x => {
                if(x.Adl2Naml === "臺東縣") {
                    x.Adl5NamlList = x.Adl5NamlList.filter(y => y !== "蘭嶼鄉").filter(z => z !== "綠島鄉");
                }
                if(x.Adl2Naml === "屏東縣") {
                    
                    x.Adl5NamlList = x.Adl5NamlList.filter(y => y !== "琉球鄉");
                }
            });
            // console.log(`Adl5List`, this.Adl5List);
        },
        handleTypeList() {
            this.typeList_l =  this.typeList.map(x => x.GType_l);
        },
        handleCompetitorList() {
            // if(this.index !== 1) return ;
            // console.log(`parentCompetitorList`, this.parentCompetitorList, `competitorList`, this.competitorList);
            
            if(this.index === 1) {
                this.competitorList.forEach(x => {
                    x.Brand.forEach(y => {
                        this.brandsDisabled[y.BrandL] = false;
                        if(!y.BrandL) {
                            y.BrandL = y.BrandE;
                        }
                        x[x.GType] = x.Brand;
                    });
                    if(x.Brand) delete x.Brand;
                    if(x.GType) delete x.GType;
                    if(x.GType_l) delete x.GType_l;
                });
            }
            else {
                this.competitorList.forEach(x => {
                    Object.values(x)[0].forEach(y => {
                        this.brandsDisabled[y.BrandL] = false;
                    })
                })
            }

            // console.log(`brandsDisabled`, this.brandsDisabled);
        },
        updateAdl2(value) {
            const selectedAdl2 = value;
            // this.formData.selectedAdl2 = selectedAdl2;
            this.filteredAdl5List = this.Adl5List.find(x => x.Adl2Naml === selectedAdl2)["Adl5NamlList"];
            // console.log(`this.filteredAdl5List`,this.filteredAdl5List);
            this.validateSlient();
        },
        onChangeSelectedBrandCount() {
            this.formData.selectedBrands = this.selectedBrands;
            // console.log(`this.formData.selectedBrands`, this.formData.selectedBrands);
            
            if(this.selectedBrands.length >= this.maxBrandCount) {
                // Disabled others
                Object.keys(this.brandsDisabled).forEach(x => {
                    if(!this.selectedBrands.includes(x)) {
                        this.brandsDisabled[x] = true;
                    }
                });
            }
            else {
                Object.keys(this.brandsDisabled).forEach(x => this.brandsDisabled[x] = false);
            }
            // console.log(`brandsDisabled`, this.brandsDisabled);
        },
        findBrandType(brand) {
            // console.log(`competitorList`, this.competitorList, `brand`, brand);
            let type = '';
            this.competitorList.forEach(x => {
                const filter = Object.values(x)[0].filter(y => y.BrandL === brand);
                // console.log(`filter`, filter);
                
                if(filter.length > 0) {
                    type =  Object.values(x)[0].filter(y => y.BrandL === brand)[0]['GType'];
                };
                
            })
            
            return "_"+this.replaceString(type);
        },
        replaceString(string) {
            // let _string = string === "3C" ? "_3C" : string;
            const regex = /[\n\r\s\t\\/\＆]+/g;
            
            return string.replace(regex, '_');
        },
        handleBadge(action, type) {
            // console.log(`type`, type);
            const tab = document.querySelector(`#${type}.nav-item`);
            // console.log(`tab`, tab);
            
            let span = document.querySelector(`#${type}.nav-item span`);
            // console.dir(`span`, span);
            if(span !== null) {
                let text = span.innerText || span.textContent;

                if(action === 'add') {
                    text = parseInt(text) + 1;
                }
                else {
                    text = parseInt(text) - 1;
                }

                if(text === 0) {
                    tab.removeChild(span);
                }
                else {
                    span.innerHTML = text.toString();
                }
            }
            else {
                let newSpan = document.createElement('span');
                newSpan.className = 'badge';
                newSpan.append("1");
                tab.append(newSpan);
            }

        },
        async validateSlient() {
            const self = this;
            let valid = false;
            this.$nextTick(async () => {
                valid = await self.$refs.vo.validate({silent: true});
                // console.log(`validateSlient`, valid);
                self.$emit('siteValidate', {
                    valid,
                    index: this.index
                });
            })
        },
    }
}
</script>

<style scoped>
.card {
    background-color: #222222;
    border-color: transparent;
    border-radius: 8px;
    border-top-left-radius: 0;
}

.star {
    position: absolute;
    left: -10px;
    z-index: 2;
}

.card ::v-deep input.form-control,
.card ::v-deep select.form-control {
    border-bottom: 1px;
    border-width: 0px 0px 1px 0;
    border-color: #fff;
    border-style: solid;
    border-radius: 0;
}


.card ::v-deep input.form-control::placeholder,
.card ::v-deep select.form-control::placeholder {
    color: #BBDDCE;
}

::v-deep .multiselect {
    min-height: 36px;
    height: 36px;
    border-width: 0px 0px 1px 0;
    border-color: #fff;
    border-style: solid;
}

::v-deep .multiselect__placeholder {
    color: #F6F6F6;
}

::v-deep .card .multiselect__select {
    top: 1px;
}

::v-deep .multiselect__tags {
    min-height: 36px;
    height: 36px;
}

::v-deep .card .multiselect .multiselect__tags {
    padding: 8px 43px 0 2px;
}

::v-deep .multiselect .multiselect__tags {
    padding-top: 6px;
}

::v-deep .multiselect__select {
    padding: 4px 4px 4px 8px;
}

::v-deep .multiselect--active .multiselect__select {
    padding: 4px 8px 4px 4px;
}

::v-deep .card .multiselect__single {
    padding-left: 0;
}

.desc {
    color: #FAFAFA;
    font-size: 14px;
    font-weight: 700;
}

.form-group {
    margin-bottom: 0;
}

::v-deep input[type="text"].form-control {
    padding-left: 5px;
}

.error-message {
    display: inline-block;
    color: #FF9292;
    margin: 3px 0 0 5px;
}

.card .error-message {
    margin: 0 0 0 2px;
}

::v-deep .nav {
    gap: 8px;
    margin-bottom: 10px;
}

::v-deep .nav-item {
    position: relative;
}

::v-deep .badge {
    position: absolute;
    top: -5px;
    right: 0;
    border-radius: 50%;
    font-weight: 600;
    cursor: pointer;
    background-color: #F6C857;
    width: 15px;
    height: 15px;
}

::v-deep .card .nav-pills .nav-link {
    border-radius: 5px;
}

::v-deep .nav-link {
    background-color: transparent;
    color: rgba(227, 227, 227, 0.7);
    border: 1px rgba(227, 227, 227, 0.7) solid;
}

::v-deep .nav-link:hover {
    color: rgba(146, 200, 176, 1);
}

::v-deep .nav-link.active {
    background: rgba(146, 200, 176, 0.20);
    color: rgba(146, 200, 176, 1);
    border-color: rgba(146, 200, 176, 1);
}

::v-deep input[type="checkbox"] {
    display: none;
}

input[type="checkbox"]+label:before {
  border: 1px solid #7f83a2;
  content: "\00a0";
  display: inline-block;
  background: rgba(248, 248, 248, 1);
  font: 14px/1em sans-serif;
  height: 14px;
  width: 14px;
  margin: 2.5px .5em 0 0;
  padding: 0;
  vertical-align: top;
}

input[type="checkbox"]:disabled+label:before {
  background: rgba(77, 77, 77, 1);
}

input[type="checkbox"]:checked+label:before {
  background: rgba(146, 200, 176, 1);
  color: rgba(77, 77, 77, 1);
  content: "\2714\fe0e";
  text-align: center;
}

input[type="checkbox"]:checked+label:after {
  font-weight: bold;
}

label {
    margin-right: 8px;
    margin-bottom: 0;
    color: #FAFAFA;
    padding: 7px 3px;
}

::v-deep .tab-pane {
    min-height: 116px;
}

</style>